<template>
  <div class="dashboard pt-3">
    <div class="row">
      <div class="col">
        <h3><font-awesome-icon icon="book" /> คำร้อง</h3>
        <hr />
        <div class="row justify-content-end align-items-center">
          <div class="col-md-3 text-end">ตัวกรอง</div>
          <div class="col-md-5">
            <select
              class="form-control"
              v-model="filter"
              @change="filterStatus"
            >
              <option value="">ไม่มีตัวกรอง</option>
              <option value="PENDING">รออนุมัติ</option>
              <option value="COMPLETE">อนุมัติเรียบร้อย</option>
              <option value="DELETE">ลบแล้ว</option>
            </select>
          </div>
        </div>

        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">ชื่อ</th>
                <th scope="col">วันที่</th>
                <th scope="col">ประเภทคำร้อง</th>
                <th scope="col">สถานะ</th>
                <th scope="col">ตัวเลือก</th>
                <th scope="col">จัดการ</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(value, index) in list" :key="index">
                <td>{{ parseInt(index) + 1 }}</td>
                <td>{{ value.name }}</td>
                <td>{{ createThaiDate(value.timestamp) }}</td>
                <td>{{ value.type }}</td>
                <td>
                  <span
                    class="text-warning"
                    v-if="
                      value.status == null ||
                      value.status == 'PENDING' ||
                      value.status == ''
                    "
                    ><font-awesome-icon
                      icon="hourglass-half"
                      title="รอการอนุมัติ"
                  /></span>
                  <span class="text-success" v-if="value.status == 'COMPLETE'"
                    ><font-awesome-icon
                      icon="check-circle"
                      title="อนุมัติเรียบร้อย"
                  /></span>
                  <span class="text-danger" v-if="value.status == 'DELETE'"
                    ><font-awesome-icon icon="trash-alt" title="ลบแล้ว"
                  /></span>
                </td>
                <td>
                  <a class="btn btn-primary" :href="'/preview/'+value.id" target="_blank">
                    <font-awesome-icon icon="eye" title="ดูตัวอย่าง" /></a
                  >&nbsp;
                  <a class="btn btn-warning" :href="'/download/'+value.id" target="_blank">
                    <font-awesome-icon icon="download" title="ดาวน์โหลด" />
                  </a>
                </td>
                <td>
                  <button
                    class="btn btn-success"
                    title="อนุมัติ"
                    @click="changeStatus(index, value, 'COMPLETE')"
                  >
                    <font-awesome-icon icon="check-circle" /></button
                  >&nbsp;
                  <button
                    class="btn btn-danger"
                    title="ลบคำร้อง"
                    @click="changeStatus(index, value, 'DELETE')"
                  >
                    <font-awesome-icon icon="trash-alt" />
                  </button>
                </td>
              </tr>
              <tr>
                <td colspan="7">
                  <button
                    class="btn btn-secondary shadow-sm w-100"
                    @click="loadMore"
                  >
                    <font-awesome-icon icon="caret-square-down" />
                    โหลดเพิ่มเติม
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- <iframe
          id="display"
          style="width: 100%; height: 100%; overflow: scroll"
          :src="src"
        ></iframe> -->
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "../db";
import "firebase/database";
const dbRefDocuments = firebase.database().ref("documents");
const dbRefInfo = firebase.database().ref("information");

import { thaiDate } from "../PDF/config.js";

export default {
  name: "DashboardDoc",
  data: function () {
    return {
      doc: "",
      info: "",
      pdf: "",
      page: 1,
      limit: 100,
      loadTotal: false,
      list: Object(),
      filter: "",
    };
  },
  mounted: async function () {
    await dbRefInfo
      .get()
      .then((snapshot) => {
        if (snapshot.exists()) {
          this.info = snapshot.val();
        } else {
          console.log("No data available");
        }
      })
      .catch((error) => {
        console.error(error);
      });

    await dbRefDocuments
      .child("0")
      .get()
      .then((snapshot) => {
        if (snapshot.exists()) {
          this.pdf = snapshot.val();
        } else {
          console.log("No data available");
        }
      })
      .catch((error) => {
        console.error(error);
      });

    this.showData();
  },
  methods: {

    createThaiDate: function (d) {
      return thaiDate(d);
    },
    showData: async function () {
      await dbRefDocuments
        .orderByKey()
        .limitToLast(this.limit)
        .get()
        .then((snapshot) => {
          if (snapshot.exists()) {
            let result = snapshot.val();
            this.sortFromLast(result);
          } else {
            console.log("No data available");
            this.loadTotal = true;
            console.log(this.loadTotal);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    showDataWithFilter: async function () {
      await dbRefDocuments
        .orderByChild("status")
        .equalTo(this.filter)
        .limitToLast(this.limit * this.page)
        .get()
        .then((snapshot) => {
          if (snapshot.exists()) {
            let result = snapshot.val();
            this.sortFromLast(result);
          } else {
            console.log("No data available");
            this.loadTotal = true;
            console.log(this.loadTotal);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    sortFromLast: function (item) {
      let new_item = Object();
      let count = 0;

      for (let x in item) {
        new_item[count] = item[x];
        new_item[count].id = x;
        count++;
      }

      let i = Object.keys(new_item).length;
      count = 0;
      for (i = i - 1; i >= 0; i = i - 1) {
        if (new_item[i] != null) {
          this.list[count] = new_item[i];
          count++;
        }
      }
    },
    filterStatus: async function () {
      this.list = Array();

      if (this.filter == "") {
        this.showData();
        return false;
      } else {
        this.showDataWithFilter();
      }
    },
    loadMore: function () {
      this.limit = this.limit + 100;
      if (this.filter == "") {
        this.showData();
      } else {
        this.showDataWithFilter();
      }
    },
    changeStatus: async function (index, value, status) {
      await dbRefDocuments
        .child(value.id)
        .update({ status: status })
        .then(() => {
          this.list[index].status = status;
        })
        .catch((error) => {
          console.error(error);
        });
      return false;
    },
  },
};
</script>

<style>
</style>